<template>
  <div class="container text-center">
    <div v-for="(item, idx) in jumpList" :key="idx" @click="jump(item.url)">
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      jumpList: [
        {
          title: "速查表",
          url: "/cheatSheet",
        },
        {
          title: "元/辅音表",
          url: "/vowelConsonant",
        },
        {
          title: "老师风采",
          url: "/teacherStyle?id=2",
        },
        {
          title: "免费领取资料包",
          url: "/free",
        },
        {
          title: "韩语入门第一课",
          url: "/classOne",
        },
        {
          title: "四十音学习",
          url: "/learn?id=1",
        },
        {
          title: "视频",
          url: "/video?id=45",
        },
        {
          title: "用户隐私",
          url: "/privacy",
        },
        {
          title: "兴趣课程",
          url: "/interest",
        },
        {
          title: "一对一辅导课程",
          url: "/coach",
        },
        {
          title: "炫耀一下",
          url: "/showoff",
        },
        {
          title: "配音秀",
          url: "/dubShow?id=1",
        },
        {
          title: "洋葱APP下载",
          url: "/download-app",
        },
        {
          title: "抖课APP下载",
          url: "dkDownload-app",
        },
        {
          title: "免费课程",
          url: "/curriculumFree",
        },
        {
          title: "课程",
          url: "/curriculum",
        },
        {
          title: "作业",
          url: "/work",
        },
        {
          title: "情景对话",
          url: "/OnionSharing",
        },
        {
          title: "雅思APP下载",
          url: "/IELTSDownload",
        },
      ],
    };
  },

  created() {},

  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  div {
    font-size: 24px;
    margin: 20px 0;
  }
}
</style>
